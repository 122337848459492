import { useQuery } from '@tanstack/react-query';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';

export const useSuperAdmin = () => {
  const { user } = useAuth();

  return useQuery({
    queryKey: ['superAdmin', user?.uid],
    queryFn: async () => {
      if (!user?.uid) return false;
      const membershipRef = collection(db, 'memberships');
      const membershipQuery = query(
        membershipRef,
        where('userId', '==', user.uid),
        where('organizationId', '==', 'oQO4a6BJoQ6oq2UJAKoh'),
        where('role', '==', 'admin'),
      );
      const membershipDocs = await getDocs(membershipQuery);
      return !membershipDocs.empty;
    },
    enabled: !!user?.uid,
  });
};
