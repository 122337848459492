import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider, createTheme, CssBaseline, Box } from '@mui/material';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import AppToolbar from './components/Toolbar';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Organizations from './components/Organizations';
import Memberships from './components/Memberships';

const queryClient = new QueryClient();

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

const PrivateRoute: React.FC<{ element: React.ReactElement }> = ({ element }) => {
  const { user, loading } = useAuth();

  if (loading) return <div>Loading...</div>;

  return user ? element : <Navigate to="/login" />;
};

const PrivateLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <>
    <AppToolbar />
    <Box sx={{ mt: 2 }}>{children}</Box>
  </>
);

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/"
                element={
                  <PrivateRoute
                    element={
                      <PrivateLayout>
                        <Dashboard />
                      </PrivateLayout>
                    }
                  />
                }
              />
              <Route
                path="/organizations"
                element={
                  <PrivateRoute
                    element={
                      <PrivateLayout>
                        <Organizations />
                      </PrivateLayout>
                    }
                  />
                }
              />
              <Route
                path="/memberships"
                element={
                  <PrivateRoute
                    element={
                      <PrivateLayout>
                        <Memberships />
                      </PrivateLayout>
                    }
                  />
                }
              />
            </Routes>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
