import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { addDoc, collection, deleteDoc, doc, getDocs, query, where } from 'firebase/firestore';
import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import { useSuperAdmin } from '../hooks/useSuperAdmin';

interface Organization {
  id: string;
  name: string;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const Organizations: React.FC = () => {
  const { user } = useAuth();
  const [newOrgName, setNewOrgName] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteOrgId, setDeleteOrgId] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const { data: isSuperAdmin } = useSuperAdmin();

  const { data: organizations, isLoading } = useQuery({
    queryKey: ['organizations', isSuperAdmin],
    queryFn: async () => {
      if (!user?.uid) return [];
      if (isSuperAdmin) {
        // Fetch all organizations for super admin
        const orgRef = collection(db, 'organizations');
        const orgDocs = await getDocs(orgRef);
        return orgDocs.docs.map((doc) => ({ ...doc.data(), id: doc.id }) as Organization);
      } else {
        const membershipRef = collection(db, 'memberships');
        const membershipQuery = query(
          membershipRef,
          where('userId', '==', user.uid),
          where('role', '==', 'admin'),
        );
        const membershipDocs = await getDocs(membershipQuery);

        const orgIds = membershipDocs.docs.map((doc) => doc.data().organizationId);

        if (orgIds.length === 0) return [];

        const orgRef = collection(db, 'organizations');
        const orgQuery = query(orgRef, where('__name__', 'in', orgIds));
        const orgDocs = await getDocs(orgQuery);

        return orgDocs.docs.map((doc) => ({ ...doc.data(), id: doc.id }) as Organization);
      }
    },
    enabled: !!user?.uid,
  });

  const addOrganization = useMutation({
    mutationFn: async (name: string) => {
      if (!user?.uid) throw new Error('User not authenticated');
      const docRef = await addDoc(collection(db, 'organizations'), { name });
      await addDoc(collection(db, 'memberships'), {
        userId: user.uid,
        organizationId: docRef.id,
        role: 'admin',
        joinedAt: new Date(),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organizations'] });
      setNewOrgName('');
    },
  });

  const deleteOrganization = useMutation({
    mutationFn: async (id: string) => {
      await deleteDoc(doc(db, 'organizations', id));
      const membershipRef = collection(db, 'memberships');
      const membershipQuery = query(membershipRef, where('organizationId', '==', id));
      const membershipDocs = await getDocs(membershipQuery);
      await Promise.all(membershipDocs.docs.map((doc) => deleteDoc(doc.ref)));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['organizations'] });
      setDeleteOrgId(null);
      setOpenDialog(false);
    },
  });

  const handleAddOrganization = () => {
    if (newOrgName.trim()) {
      addOrganization.mutate(newOrgName.trim());
    }
  };

  const handleDeleteOrganization = (id: string) => {
    setDeleteOrgId(id);
    setOpenDialog(true);
  };

  const confirmDelete = () => {
    if (deleteOrgId) {
      deleteOrganization.mutate(deleteOrgId);
    }
  };

  if (isLoading)
    return (
      <Container maxWidth="md">
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
        >
          <Typography>Loading...</Typography>
        </Box>
      </Container>
    );

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Organizations
        </Typography>
        <StyledPaper>
          <Typography variant="h6" gutterBottom>
            Add New Organization
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={9}>
              <TextField
                fullWidth
                label="New Organization Name"
                variant="outlined"
                value={newOrgName}
                onChange={(e) => setNewOrgName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddOrganization}
                disabled={!newOrgName.trim()}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </StyledPaper>
        <StyledPaper>
          <Typography variant="h6" gutterBottom>
            Your Organizations
          </Typography>
          <List>
            {organizations?.map((org, index) => (
              <React.Fragment key={org.id}>
                {index > 0 && <Divider />}
                <ListItem>
                  <ListItemText primary={org.name} secondary={`ID: ${org.id}`} />
                  <ListItemSecondaryAction>
                    <Tooltip
                      title="Delete Organization"
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 600 }}
                    >
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDeleteOrganization(org.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              </React.Fragment>
            ))}
            {organizations?.length === 0 && (
              <ListItem>
                <ListItemText primary="No organizations found" />
              </ListItem>
            )}
          </List>
        </StyledPaper>
      </Box>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this organization? This action cannot be undone and will
            remove all associated memberships.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={confirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Organizations;
