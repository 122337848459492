import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Typography, Button, Grid, Paper, Box } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';

const Dashboard: React.FC = () => {
  const { user, signOut } = useAuth();

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Dashboard
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Welcome, {user?.email}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Organizations
              </Typography>
              <Button
                component={RouterLink}
                to="/organizations"
                variant="contained"
                color="primary"
              >
                Manage Organizations
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Memberships
              </Typography>
              <Button component={RouterLink} to="/memberships" variant="contained" color="primary">
                Manage Memberships
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Dashboard;
